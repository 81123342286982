<template>
  <v-navigation-drawer v-model="value" clipped app>
    <v-list-item>
      <v-list-item-content class="ma-0">
        <v-img :src="pathLogo" />
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list-item v-if="isLoggedIn" two-line color="success">
      <v-list-item-avatar size="50" color="primary">
        <!-- <v-icon color="white">mdi-account</v-icon> -->
        <span class="white--text headline text-uppercase">{{ userInitials }}</span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ loggedUser.username }}</v-list-item-title>
        <v-list-item-subtitle>{{ loggedUser.roles?loggedUser.roles[0]:'' }}</v-list-item-subtitle>
        <v-list-item-subtitle :class="getColorEnvironment()">{{ getStringEnvironment() }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list v-for="(section, s) of items" :key="s" dense class="py-0">
      <!-- :color="section.color" color="blue darken-3" src="assets/user-profile-bg.jpg" -->
      <v-toolbar height="30" dense :color="section.color" v-if="isSectionAuth(section)" >
        <span class="white--text text-h6 text-uppercase">
          {{ section.label }}
        </span>
        <v-spacer />
        <v-btn icon small @click="section.menuTreeVisible=!section.menuTreeVisible">
          <v-icon v-if="section.menuTreeVisible" small color="white">
            mdi-minus
          </v-icon>     
          <v-icon v-else small color="white">
            mdi-plus
          </v-icon>      
        </v-btn>  
      </v-toolbar>
      <div v-if="section.menuTreeVisible">
        <div v-for="(item, i) of section.menuTree.filter(a => isAuth(a))" :key="i">
          <div v-if="item.items">
            <v-list-group v-if="item.items && item.items.length" :prepend-icon="item.icon" :value="isSubMenuListOpen(item.items)">
              <template v-slot:activator>
                <v-list-item-title v-text="item.label" />
              </template>
              <v-list-item v-for="(subItem, isub) of item.items.filter(a => isAuth(a))" :key="isub" link :to="subItem.to" @click="resetCookies">
                <v-list-item-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.label" />
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
          <div v-else>
            <v-list-item link :to="item.to" @click="resetCookies">
              <v-list-item-action v-if="!item.items">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.label" />
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </div>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block @click="onLogoutClick" color="secondary">
          {{ tLabel("logout") }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import railsMixins from "./rails/mixins/railsMixins";

export default {
  name: "AppMenu",
  data() {
    return {
      customer: process.env.VUE_APP_CUSTOMER || "logoRail",
      expansionPanel: [0],
    };
  },
  mixins: [railsMixins],
  props: {
    items: Array,
    value: Boolean,
  },
  computed: {
    ...mapGetters("authentication", ["isLoggedIn", "loggedUser"]),
    pathLogo() {
      let basePath = "assets/";
      let img = this.customer + ".jpg";
      return basePath + this.customer + "/" + img;
    },
    userInitials() {
      return this.loggedUser.username.charAt(0);
    },
  },
  methods: {
    ...mapActions("authentication", ["logout"]),
    onLogoutClick() {
      this.resetCookies();
      this.logout();
      this.$router.push({ path: "/login" });
    },
    resetCookies() {
      localStorage.removeItem("railsWeekCalendarParkFilter")
      localStorage.removeItem("railsWeekCalendarStartDateFilter")
    },
    isSubMenuListOpen(subItems) {
      return this._.find(subItems, i => i.to && i.to === this.$route.path);
    },
    isAuth(item) {
      if(item.env){
        const v=process.env["VUE_APP_"+item.env];
        const value=v !=undefined ? v : 'false';
        const expected=item.env_value !=undefined ? item.env_value : 'true';
        const retValue= (value == expected);
        return retValue;
      }
      if(item.visible){
        const value=process.env["VUE_APP_"+item.visible]
        if(!value || value==='false'){
          return false;
        }
      }
      if (!item.role) return true;
      const roles = this.$store.getters["authentication/loggedUser"].roles;
      if (roles) {
        if(item.role.indexOf("|")>0){
          const isVisible=item.role.split("|").some(role=> roles.includes(role));
          return isVisible;
        }
        const isVisible=item.role.split(",").every(role=> roles.includes(role));
        //console.log(item.label, item.role, isVisible);
        return isVisible;
      }
    },
    isSectionAuth(section) {
      var ret = false;
      var i;
      for (i = 0; i < section.menuTree.length; i++) {
        if (this.isAuth(section.menuTree[i])) {
          ret = true;
          break;
        }       
      }
      return ret;
    },
    getColorEnvironment() {
      const environment = this.getEnvironment();
      if (environment === "PROD") {
        return "blue--text";
      } else if (environment === "TEST" || environment === "DEV") {
        return "deep-orange--text";
      }
    },
    getStringEnvironment() {
      const environment = this.getEnvironment();
      if (environment === "PROD") {
        return "Prod Environment";
      } else if (environment === "TEST") {
        return "Test Environment";
      } else if (environment === "DEV") {
        return "Dev Environment";
      }
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>
