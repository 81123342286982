import api from '../api';
import apiConfiguration from '../apiConfiguration';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import store from '../../../store/';

export default {
  loadViaggioTreno: viaggioTrenoId => {
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/pianificazione/${viaggioTrenoId}`;
    return api.get(fullPath);
  },
  getTracceCollegate: tracciaId => {
    const fullPath = `${apiConfiguration.BASE_PATH}tracce/findTracceLegate/${tracciaId}`;
    return api.get(fullPath);
  },
  getTracciaById: tracciaId => {
    const fullPath = `${apiConfiguration.BASE_PATH}tracce/${tracciaId}`;
    return api.get(fullPath);
  },
  getLegamentiTracce: tracciaId => {
    const fullPath = `${apiConfiguration.BASE_PATH}lagamentiTracce/findByTracciaId/${tracciaId}`;
    return api.get(fullPath);
  },
  generaViaggiTreno: (generaViaggiDa, generaViaggiA) => {
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/genera`;
    return api.post(fullPath, { dateFrom: generaViaggiDa, dateTo: generaViaggiA });
  },
  getNodiTracce: () => {
    const fullPath = `${apiConfiguration.BASE_PATH}tracce/findNodi`;
    return api.get(fullPath);
  },
  getTracce: () => {
    const fullPath = `${apiConfiguration.BASE_PATH}tracce/findTracce`;
    return api.get(fullPath);
  },
  getVettoriFerroviari: () => {
    const fullPath = `${apiConfiguration.BASE_PATH}tracce/findVettoriFerroviari`;
    return api.get(fullPath);
  },
  getMTO: () => {
    const fullPath = `${apiConfiguration.BASE_PATH}tracce/findMTO`;
    return api.get(fullPath);
  },
  updateViaggioTrenoStatoViaggio: (id, state) => {
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/updateStatoViaggio`;
    return api.post(fullPath, { id: id, state: state });
  },

  updateViaggioTrenoStatoViaggioSopresso: (id, state, azione) => {
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/updateStatusSuppressed`;
    return api.post(fullPath, { id: id, state: state, azione: azione });
  },

  cancellazioneLogicaViaggio: id => {
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/cancellazioneLogica`;
    return api.post(fullPath, { id: id });
  },

  updateViaggioTrenoStatoOperativo: (id, state) => {
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/updateStatoOperativo`;
    return api.post(fullPath, { id: id, state: state });
  },

  setViaggioTrenoPianificato(id, value){
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/set-pianificato/${id}`;
    return api.post(fullPath, {value: value});
  },

  reopen: id => {
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/reopen/${id}`;
    return api.post(fullPath);
  },
  getConvoysToPlan: (dateFrom, dateTo) => {
    const fullPath = `${apiConfiguration.BASE_PATH}convogli/findByDateRange`;
    return api.get(fullPath, {
      params: { dateFrom: dateFrom, dateTo: dateTo },
    });
  },
  getSimpleConvoysToPlan: (dateFrom, dateTo) => {
    const fullPath = `${apiConfiguration.BASE_PATH}convogli/findConvoglioByDateRange`;
    return api.get(fullPath, {
      params: { dateFrom: dateFrom, dateTo: dateTo },
    });
  },
  getTracksOccupancies: (dateFrom, dateTo) => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/occupazione-binari`;
    return api.get(fullPath, {
      params: { dateFrom: dateFrom, dateTo: dateTo },
    });
  },    
  updateConvoy: convoglio => {
    console.log("UPDATE CONVOGLIO ", convoglio);
    let convoglioTs = Object.assign({}, convoglio);
    if (convoglioTs.binario) {
      convoglioTs.binarioId = convoglioTs.binario.id;
    } else {
      delete convoglioTs.binarioId;
    }
    convoglioTs.posizioneBinario = convoglio.posizioneBinario ? convoglio.posizioneBinario : 0;
    convoglioTs.eta = moment(convoglioTs.eta).toISOString(true);
    convoglioTs.etd = moment(convoglioTs.etd).toISOString(true);
    convoglioTs.ata = moment(convoglioTs.ata).toISOString(true);
    convoglioTs.atd = moment(convoglioTs.atd).toISOString(true);
    convoglioTs.processingTimeStart = moment(convoglioTs.processingTimeStart).toISOString(true);
    convoglioTs.processingTimeEnd = moment(convoglioTs.processingTimeEnd).toISOString(true);
    convoglioTs.exportProcessingTimeStart = moment(convoglioTs.exportProcessingTimeStart).toISOString(true);
    convoglioTs.exportProcessingTimeEnd = moment(convoglioTs.exportProcessingTimeEnd).toISOString(true);
    convoglioTs.importProcessingTimeStart = moment(convoglioTs.importProcessingTimeStart).toISOString(true);
    convoglioTs.importProcessingTimeEnd = moment(convoglioTs.importProcessingTimeEnd).toISOString(true);

    if (convoglioTs.listCarri && convoglioTs.listCarri.length) {
      convoglioTs.listCarri.forEach(cc => {
        delete cc.convoglio;
        delete cc.viaggioIn;
        delete cc.viaggioOut;
        cc.visitaCarro.arrivo = moment(cc.visitaCarro.arrivo).toISOString(true);
        cc.visitaCarro.partenza = moment(cc.visitaCarro.partenza).toISOString(true);
        delete cc.visitaCarro.carroConvoglioList;
        delete cc.visitaCarro.carroViaggioList;
      });
    }
    const fullPath = `${apiConfiguration.BASE_PATH}convogli/edit`;
    return api.post(fullPath, convoglioTs);
  },
  updateConvoyData: convoglio => {
    const reqObj = {
      idConvoglio: convoglio.id,
      nome: convoglio.nome,
      eta: moment(convoglio.eta).toISOString(true),
      etd: moment(convoglio.etd).toISOString(true),
      carriConvoglio: convoglio.listCarri
        ? _.transform(
            convoglio.listCarri,
            (result, cc) => {
              result.push({
                idCarroConvoglio: cc.id,
                posizione: cc.posizione,
              });
              return result;
            },
            []
          )
        : null,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/updateData`;
    return api.post(fullPath, reqObj);
  },
  updateConvoyPlanning: planning => {
    const fullPath = `${apiConfiguration.BASE_PATH}convogli/planning`;
    return api.post(fullPath, planning);
  },
  getConvoglioById: id => {
    const fullPath = `${apiConfiguration.BASE_PATH}convogli/${id}`;
    return api.get(fullPath);
  },
  getViaggioById: id => {
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/${id}`;
    return api.get(fullPath);
  },
  getStatiViaggioTreno: () => {
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/statiViaggioTreno`;
    return api.get(fullPath);
  },
  gateInConvoglio: (convoglio, binario, pos, dataOperazione) => {
    if (!binario) throw "Nessun binario selezionato";
    if (!dataOperazione) throw "Nessuna data selezionata";
    const reqObj = {
      idConvoglio: convoglio.id,
      idBinario: binario.id,
      posizioneBinario: pos,
      dataOperazione: dataOperazione,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/gateIn`;
    return api.post(fullPath, reqObj);
  },
  annullaGateInConvoglio: convoglio => {
    const reqObj = {
      idConvoglio: convoglio.id,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/annullaGateIn`;
    return api.post(fullPath, reqObj);
  },
  getEnableBtnAnnullaGateIn: convoglio => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/getEnableBtnAnnullaGateIn/${convoglio.id}`;
    return api.get(fullPath);
  },

  gateOutConvoglio: (convoglio, dataOperazione) => {
    if (!dataOperazione) throw "Nessuna data selezionata";
    const reqObj = {
      idConvoglio: convoglio.id,
      dataOperazione: dataOperazione,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/gateOut`;
    return api.post(fullPath, reqObj);
  },
  annullaGateOutConvoglio: convoglio => {
    const reqObj = {
      idConvoglio: convoglio.id,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/annullaGateOut`;
    return api.post(fullPath, reqObj);
  },
  reIngressoConvoglio: convoglio => {
    const reqObj = {
      idConvoglio: convoglio.id,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/reIngressoConvoglio`;
    return api.post(fullPath, reqObj);
  },
  shuntingConvoglio: (convoglio, binario, pos, dataOperazione) => {
    if (!binario) throw "Nessun binario selezionato";
    const reqObj = {
      idConvoglio: convoglio.id,
      idBinario: binario.id,
      posizioneBinario: pos,
      dataOperazione: dataOperazione,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/shunting`;
    return api.post(fullPath, reqObj);
  },
  createNewConvoglio: (convoglio, ccIdList, binario, pos, dataOperazione) => {
    const reqObj = {
      convoglioId: convoglio.id,
      carriConvoglioIdList: ccIdList,
      binarioId: binario ? binario.id : null,
      posizioneBinario: pos,
      dataOperazione: dataOperazione,
    };
    // console.log("createNewConvoglio reqObj ------------>", reqObj);
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/spezzaConvoglio`;
    return api.post(fullPath, reqObj);
  },
  associaCarriConvoglio: (newConvoglio, oldConvoglio, ccIdList, operationDate) => {
    const reqObj = {
      newConvoglioId: newConvoglio.id,
      oldConvoglioId: oldConvoglio.id,
      carriConvoglioIdList: ccIdList,
      operationDate: operationDate,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/associaCarriConvoglio`;
    return api.post(fullPath, reqObj);
  },
  associaCarriViaggioPartenza: (convoglio, viaggio, ccIdList) => {
    const reqObj = {
      convoglioId: convoglio.id,
      viaggioId: viaggio.id,
      carriConvoglioIdList: ccIdList,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/associaViaggioPartenzaConvoglio`;
    return api.post(fullPath, reqObj);
  },
  associaConvoglioViaggioPartenza: (convoglio, viaggio) => {
    const reqObj = {
      convoglioId: convoglio.id,
      viaggioId: viaggio.id,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/associaViaggioPartenzaConvoglio`;
    return api.post(fullPath, reqObj);
  },
  disassociaConvoglio:  (convoglioId) => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/disassociaConvoglio/${convoglioId}`;
    return api.post(fullPath, {});
  },
  rimuoviCarriViaggioPartenza: (viaggio, carro) => {   
    const reqObj = {
      viaggioId: viaggio.id,
      carroConvoglio: carro.id,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/annullaViaggioPartenzaConvoglio`;
    return api.post(fullPath, reqObj);
  },
  joinToConvoglio: (convoglio, ccIdList, binario, pos) => {
    const reqObj = {
      convoglioId: convoglio.id,
      carriConvoglioIdList: ccIdList,
      binarioId: binario ? binario.id : null,
      posizioneBinario: pos,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/spezzaConvoglio`;
    return api.post(fullPath, reqObj);
  },
  associaCarroParco: carroConvoglio => {
    const reqObj = {
      parcoId: carroConvoglio.visitaCarro.parcoDestinazione ? carroConvoglio.visitaCarro.parcoDestinazione.id : null,
      visitaId: carroConvoglio.visitaCarro.id,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/associaCarroParco`;
    return api.post(fullPath, reqObj);
  },
  getParcoPerRicercaCarro: targa => {
    const reqObj = {
      targa: targa,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/getParcoPerRicercaCarro/${targa}`;
    return api.get(fullPath);
  },
  getBookingsByViaggio: viaggioId => {
    const fullPath = `${apiConfiguration.BASE_PATH}bookings/getByViaggio/${viaggioId}`;
    return api.get(fullPath);
  },
  cancellaBooking: bookingId => {
    const fullPath = `${apiConfiguration.BASE_PATH}bookings/cancellaBooking/${bookingId}`;
    return api.post(fullPath);
  },
  spostaUtiPianificataInRiserve: utiCarro => {
    //TODO:rivedere la logica di spostamento nelle riserve (proabilmente va rimossa associazione con viaggio)
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/spostaUtiPianificataInRiserve/${utiCarro.id}`;
    return api.post(fullPath, null);
  },
  associaUtiCarro: (booking, carroViaggio) => {
    const reqObj = {
      bookingId: booking.id,
      carroViaggioId: carroViaggio.id,
      posizione: booking.posizioneCarroViaggio,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/associaUtiCarro`;
    return api.post(fullPath, reqObj);
  },
  moveUtiToWagon: (visitaUtiId, carroViaggioId) => {
    const reqObj = {
      visitaUtiId: visitaUtiId,
      carroViaggioId: carroViaggioId
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/spostaUtiCarro`;
    return api.post(fullPath, reqObj);
  },
  cancellaUtiCarro: utiCarro => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/cancellaUtiCarro/${utiCarro.id}`;
    return api.post(fullPath, null);
  },
  cancellaTuttiCarroViaggio: viaggioId => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/cancellaTuttiCarroViaggio/${viaggioId}`;
    return api.post(fullPath, null);
  },
  associaTipoCarroViaggio: (viaggioId, tipoCarro, numCarri) => {
    const reqObj = {
      viaggioId: viaggioId,
      tipoCarroId: tipoCarro.id,
      numCarri: numCarri,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/associaTipoCarroViaggio`;
    return api.post(fullPath, reqObj);
  },
  cancellaCarroViaggio: carroViaggio => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/cancellaCarroViaggio/${carroViaggio.id}`;
    return api.post(fullPath, null);
  },
  getTipiCarro: () => {
    const fullPath = `${apiConfiguration.BASE_PATH}tipoCarri/list`;
    return api.get(fullPath);
  },
  associaConvoglioViaggio: (viaggioId, convoglio) => {
    const reqObj = {
      viaggioId: viaggioId,
      convoglioId: convoglio.id,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/associaConvoglioViaggio`;
    return api.post(fullPath, reqObj);
  },
  associaCarriConvoglioViaggio: (viaggioId, carriConvoglio) => {
    const reqObj = {
      viaggioId: viaggioId,
      carriConvoglioIdList: [],
    };
    carriConvoglio.forEach(cc => reqObj.carriConvoglioIdList.push(cc.id));
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/associaCarriConvoglioViaggio`;
    return api.post(fullPath, reqObj);
  },
  confermaPianificazioneViaggio: viaggioId => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/confermaPianificazione/${viaggioId}`;
    return api.post(fullPath, null);
  },
  confermaListaTrenoViaggio: viaggioId => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/confermaListaTreno/${viaggioId}`;
    return api.post(fullPath, null);
  },
  riaperturaPianificazione: viaggioId => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/riaperturaPianificazione/${viaggioId}`;
    return api.post(fullPath, null);
  },
  salvaUtiCarroSettings: utiCarro => {
    const reqObj = {
      utiCarroId: utiCarro.id,
      tassativoTipoCarro: utiCarro.tassativoTipoCarro,
      tassativoCarro: utiCarro.tassativoCarro,
      portaAPorta: utiCarro.portaAPorta,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneViaggi/salvaUtiCarroSettings`;
    return api.post(fullPath, reqObj);
  },

  findAllConvogliByViaggioTreno: id => {
    const fullPath = `${apiConfiguration.BASE_PATH}convogli/findAllByViaggioTreno/${id}`;
    return api.get(fullPath);
  },

  findAllConvogliByCarroConvoglioViaggioTreno: id => {
    const fullPath = `${apiConfiguration.BASE_PATH}convogli/findAllByCarroConvoglioViaggioTreno/${id}`;
    return api.get(fullPath);
  },

  // TODO rinominare
  findAllConvogliByViaggioTrenoPartenza: id => {
    const fullPath = `${apiConfiguration.BASE_PATH}convogli/findAllByViaggioTrenoPartenza/${id}`;
    return api.get(fullPath);
  },

  updateUtiCarroList: list => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/updateUtiCarroList`;
    return api.post(fullPath, list);
  },

  saveIstruzioniOperative: (id, istruzioniOperative) => {
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/saveIstruzioniOperative`;
    const reqObj = {
      id: id,
      istruzioniOperative: istruzioniOperative,
    };
    return api.post(fullPath, reqObj);
  },
  getViaggiArrivoLavorazioneCrane: () => {
    return api.get(`${apiConfiguration.BASE_PATH}lavorazioni/viaggiLavorazione/all`, {});
  },
  getViaggiArrivoPianificati: () => {
    return api.get(`${apiConfiguration.BASE_PATH}lavorazioni/viaggiLavorazione/pianificati`, {});
  },
  getViaggiArrivoPianificatiInLavorazione: () => {
    return api.get(`${apiConfiguration.BASE_PATH}lavorazioni/viaggiLavorazione/pianificatiInLavorazione`, {});
  },

  getDatiLavorazione: id => {
    return api.get(`${apiConfiguration.BASE_PATH}lavorazioni/datiLavorazione/${id}`);
  },

  eliminaLavorazione: id => {
    return api.delete(`${apiConfiguration.BASE_PATH}lavorazioni/${id}`);
  },

  newLavorazioneFromConvogliId: convogliId => {
    const fullPath = `${apiConfiguration.BASE_PATH}lavorazioni/newFromConvogliId`;
    return api.post(fullPath, { convogliId: convogliId });
  },

  setLavorazionePriority: (lavorazioneId, type, value) => {
    const fullPath = `${apiConfiguration.BASE_PATH}lavorazioni/setPriority`;
    const reqObj = {
      lavorazioneId: lavorazioneId,
      type: type,
      value: value,
    };
    return api.post(fullPath, reqObj);
  },

  changeLavorazioneOrientation: lavorazioneId => {
    const fullPath = `${apiConfiguration.BASE_PATH}lavorazioni/changeOrientation`;
    return api.post(fullPath, lavorazioneId);
  },

  setLavorazioneStatoPronta: (lavorazioneId, pronto) => {
    const fullPath = `${apiConfiguration.BASE_PATH}lavorazioni/setStatoPronto`;
    const reqObj = {
      lavorazioneId: lavorazioneId,
      pronto: pronto,
    };
    return api.post(fullPath, reqObj);
  },

  uploadDistintaTreno: (url, file, onUploadProgress) => {
    let formData = new FormData();
    formData.append("file", file);
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${store.getters["authentication/authToken"]}`,
    };
    return axios.post(apiConfiguration.BASE_PATH + url, formData, {
      headers: headers,
      onUploadProgress,
    });
  },
  doRequestCosmosLoadingList: codiceEsterno => {
    const fullPath = `${apiConfiguration.BASE_PATH}cosmos/requestLoadingList`;
    return api.post(fullPath, { codiceCosmos: codiceEsterno });
  },
  doRequestCosmosUnloadingUti: codiceEsterno => {
    const fullPath = `${apiConfiguration.BASE_PATH}cosmos/requestUnloadingUti`;
    return api.post(fullPath, { codiceCosmos: codiceEsterno });
  },
  doRequestCosmosUnloadingList: codiceEsterno => {
    const fullPath = `${apiConfiguration.BASE_PATH}cosmos/requestUnloadingList`;
    return api.post(fullPath, { codiceCosmos: codiceEsterno });
  },
  doRequestCosmosExportHandlingInExe: codiceEsterno => {
    const fullPath = `${apiConfiguration.BASE_PATH}cosmos/requestExportHandlingInExe`;
    return api.post(fullPath, { codiceCosmos: codiceEsterno });
  },
  doRequestCosmosImportRailRegistration: codiceEsterno => {
    const fullPath = `${apiConfiguration.BASE_PATH}cosmos/requestImportRailRegistration`;
    return api.post(fullPath, { codiceCosmos: codiceEsterno });
  },
  doRequestCosmosImportHandlingInExe: codiceEsterno => {
    const fullPath = `${apiConfiguration.BASE_PATH}cosmos/requestImportHandlingInExe`;
    return api.post(fullPath, { codiceCosmos: codiceEsterno });
  },
  doRequestCosmosImportUtiState: codiceEsterno => {
    const fullPath = `${apiConfiguration.BASE_PATH}cosmos/requestImportUtiState`;
    return api.post(fullPath, { codiceCosmos: codiceEsterno });
  },

  updateStatoRifiutato: (utiCarroId, stato, statoPrec, rifiutato) => {
    const fullPath = `${apiConfiguration.BASE_PATH}utiCarro/updateStatoRifiutato`;
    const reqObj = {
      utiCarroId: utiCarroId,
      stato: stato,
      statoPrec: statoPrec,
      rifiutato: rifiutato,
    };
    return api.post(fullPath, reqObj);
  },
  scaricaConvoglio: (convoglioId, area) => {
    const fullPath = `${apiConfiguration.BASE_PATH}convogli/scaricaConvoglio`;
    return api.post(fullPath, {
      "convoglioId": convoglioId,
      "area": area});
  },
  getVisiteUtiByViaggioTrenoId: viaggioTrenoId => {
    return api.get(`${apiConfiguration.BASE_PATH}visiteUti/getByViaggioTrenoId/${viaggioTrenoId}`);
  },
  getCarriViaggiByViaggioTrenoId: viaggioTrenoId => {
    return api.get(`${apiConfiguration.BASE_PATH}visiteUti/getByViaggioTrenoId/${viaggioTrenoId}`);
  },
  associaVisitaUtiToCarroConvoglio: (carroConvoglioId, tipoMovimento, visiteUtiId, colli, isCompleted) => {
    const fullPath = `${apiConfiguration.BASE_PATH}visiteUti/associaVisitaUtiToCarroConvoglio`;
    const reqObj = {
      carroConvoglioId: carroConvoglioId,
      tipoMovimento: tipoMovimento,
      visiteUtiId: visiteUtiId,
      colli: colli,
      isCompleted: isCompleted,
    };
    return api.post(fullPath, reqObj);
  },

  caricaUtiCarroToCarroConvoglio: (convoglioId, carroConvoglioId, utiCarroId, stato, isCompleted) => {
    const fullPath = `${apiConfiguration.BASE_PATH}utiCarro/caricaUtiCarroToCarroConvoglio`;
    const reqObj = {
      convoglioId: convoglioId,
      carroConvoglioId: carroConvoglioId,
      utiCarroId: utiCarroId,
      stato: stato,
      isCompleted: isCompleted,
    };
    return api.post(fullPath, reqObj);
  },
  deleteRiattivaCarroConvoglio: (idCarroConvoglio, posizione, daCancellare) => {
    const fullPath = `${apiConfiguration.BASE_PATH}carriConvoglio/deleteRiattivaCarroConvoglio`;
    const reqObj = {
      idCarroConvoglio: idCarroConvoglio,
      posizione: posizione,
      daCancellare: daCancellare,
    };
    return api.post(fullPath, reqObj);
  },
  getConvoglioWithCarriConvogliCancellati: idCarroConvoglio => {
    return api.get(`${apiConfiguration.BASE_PATH}convogli/getConvoglioWithCarriConvogliCancellati/${idCarroConvoglio}`);
  },

  saveCarroConvogliPosizioneOriginale: convoglioId => {
    const fullPath = `${apiConfiguration.BASE_PATH}convogli/savePosizioneOriginale`;
    return api.post(fullPath, convoglioId);
  },
  creaAggiornaConvoglio: (convoglio, viaggioId, ccIdList) => {
    const reqObj = {
      convoglioId: convoglio ? convoglio.id : null,
      viaggioId: viaggioId,
      carriConvoglioIdList: ccIdList,
    };
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/compose-departure-convoy`;
    return api.post(fullPath, reqObj);
  },

  getViaggiPartenzaPresenti: () => {
    return api.get(`${apiConfiguration.BASE_PATH}viaggiTreno/viaggi-partenza-presenti`, {});
  },

  cancellazioneParcoBinario: id => {
    const fullPath = `${apiConfiguration.BASE_PATH}parcoBinari/cancellazioneParcoBinario`;
    return api.post(fullPath, { id: id });
  },

  prepareGateOut: convoglio => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/prepareGateOut/${convoglio.id}`;
    return api.get(fullPath);
  },

  prepareProntoUscita: convoglio => {
    const fullPath = `${apiConfiguration.BASE_PATH}convogli/prepareProntoUscita/${convoglio.id}`;
    return api.get(fullPath);
  },

  dissociaCarri: convoglio => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/dissociaCarriConvoglio/${convoglio.id}`;
    return api.get(fullPath);
  },
  exportListUti: id => {
    const _callConfig = {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Pragma: "no-cache",
      },
    };
    const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/exportListUti`;
    return api.post(fullPath, { id: id }, _callConfig);
  },

  undoLastMove: (id) => {
    const fullPath = `${apiConfiguration.BASE_PATH}gestioneConvogli/deleteLastMove`;
    return api.post(fullPath, id);
  },
  getNodoDefault: () => {
    const fullPath = `${apiConfiguration.BASE_PATH}nodi/endPoints`;
    return api.get(fullPath);
  }
};