<template>
  <v-app id="inspire">
    <AppMenu 
      v-if="isLoggedIn" 
      :items="menuTree" 
      v-model="drawer" 
    />  
    <AppTopBar 
      v-if="isLoggedIn" 
      v-model="drawer" 
    />
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="50" />
    </v-overlay>
  </v-app>
</template>

<script>
import AppTopBar from './components/AppTopbar';
import AppMenu from './components/AppMenu';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      drawer: true,
      useSocket: null,
    };
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
  },
 watch: {
    isLoggedIn: function (value) {
      if(value){
        this.disableMenuIfGruista();
      }
    },
  },
  async created(){
  },
  beforeMount(){
    const app = document.createElement('div');
    app.setAttribute('data-app', true);
    document.body.append(app);
    this.useSocket=Vue.apiConfiguration.RAIL_SOCKET_URL;
    if(this.useSocket) this.subscribe();
  },
  async destroyed(){
    if(this.useSocket) await this.$wsHubUnRegister("generic");
     console.log("destroyed");
  },
  computed: {
    ...mapState('app', ['isLoading', 'refCount', 'isMenuVisible']),
    ...mapGetters('authentication', ['isLoggedIn']),
    ...mapGetters('app', ['menuTree']),
  },
  methods: {
    disableMenuIfGruista(){
      const loggedUser=this.$store.getters['authentication/loggedUser'];
      if (loggedUser!=null) {
        this.drawer=loggedUser.roles.toString() != "GRUISTA";
      }
    },
    async subscribe(){
      await this.$wsHubRegister("generic", {}, (data) =>{
      console.log("Received generic event ", data);
      if (data.message!=null) this.$eventBus.$emit("RailsEvent."+data.message, data.data);
     })
    },
    async unsubscribe(){
       await this.$wsHubUnRegister("generic");
    },
  },  
};
</script>

<style lang="scss">
@import "./App.scss";

.v-picker__title{
  color: #000000 !important;
}

.v-picker__title__btn--active{
  opacity: 0 !important;
}
</style>
