import api from "../api";
import apiConfiguration from "../apiConfiguration";
import _ from "lodash";

export default {
  async getListReportHeader(typeReport, page, size) {
    return await api.get(apiConfiguration.BASE_PATH_REPORT + "terminal-reports/list/"+typeReport+"?page=" + page + "&size=" + size + "&sort=id,DESC", {});
    //return api.get(apiConfiguration.BASE_PATH_REPORT + "terminal-reports/list/"+typeReport, { page: page, size: size, sort: 'id, DESC' });
  },
  async report(headerReportReq) {
    return await api.post(apiConfiguration.BASE_PATH_REPORT + "terminal-reports/report", headerReportReq);
  }
};
