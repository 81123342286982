import Vue from 'vue';
import VueNativeSock from 'vue-native-websocket';
import wsPlugin from '../service/ws/vuePlugin';

if(Vue.apiConfiguration.RAIL_SOCKET_URL) {
    Vue.use(VueNativeSock, `${Vue.apiConfiguration.RAIL_SOCKET_URL}`, {
      reconnection: true, // (Boolean) whether to reconnect automatically (false)
      reconnectionAttempts: 10, // (Number) number of reconnection attempts before giving up (Infinity),
      reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
      format: 'json'
    });

    Vue.use(wsPlugin);
}