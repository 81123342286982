import Vue from 'vue';
import moment from 'moment';

const utilities = {
  dom: {
    addHandler : (element, types, handler) => {
      types.forEach(type => {
        if (element.addEventListener){
          element.addEventListener(type,handler,false);
        } else if (element.attachEvent){
          element.attachEvent("on" + type,handler);
        } else {
          element["on" + type] = handler;
        }
      });      
    },
    removeHandler : (element, types, handler) => {
      types.forEach(type => {
        if (element.removeEventListener){
          element.removeEventListener(type,handler,false);
        } else if (element.detachEvent){
          element.detachEvent("on" + type,handler);
        } else {
          element["on" + type] = null;
        }
      });      
    },
    detectMouseWheelDirection: (e) =>
    {
      let delta = null;
      if ( !e ) { 
        e = window.event;
      }
      if (e.wheelDelta !== undefined) {
        delta = e.wheelDelta / 60;
      } else if (e.detail) { 
        delta = -e.detail / 2;
      } else if (e.deltaY) {
        delta = -e.deltaY;
      } 
      return delta !== null && delta >= 0 ? 'up' : 'down';
    }
  },
  dates: {
    formatDate(date){
      return moment(date).format('DD/MM/YYYY');
    },
    formatDateTime(date){
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    isoFormatDate(date){
      return moment(date).format('YYYY-MM-DD');
    },
    getTimeFromDate(date){
      return moment(date).format('HH:mm');
    },
    getTimeFormatFromTs(ts){
      return moment(+ts).format('HH:mm');
    },
    getTsFromDateFormat(dateFormat){
      return moment(dateFormat).format('x');
    }
  }
}

Vue.prototype.$utilities = utilities;