import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './plugins';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'typeface-roboto/index.css';
import './assets/layout/layout.scss';

import vuetify from './plugins/vuetify';
import i18n from './i18n'

Vue.config.productionTip = false;

Vue.eventBus = new Vue();
Vue.prototype.$eventBus = Vue.eventBus;

new Vue({
    router,
    store,
    beforeCreate() {
        this.$store.commit('configuration/initialize');
        
    },
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');