import api from "../api";
import apiConfiguration from "../apiConfiguration";
import _ from "lodash";

export default {
  async getVoyagesByDateRange(dateFrom, dateTo) {
    const fullPath = `${apiConfiguration.BASE_PATH_RAIL_GATE}rail-tos/voyages`;
    return await api.get(fullPath, {
        params: { dateFrom: dateFrom, dateTo: dateTo }
    });
  },
  async getVoyageByUid(uid) {
    const fullPath = `${apiConfiguration.BASE_PATH_RAIL_GATE}rail-tos/voyage/${uid}`;
    return await api.get(fullPath);
  },
  async getVoyageByVoyageStartingWith(prefix) {
    const fullPath = `${apiConfiguration.BASE_PATH_RAIL_GATE}rail-tos/voyages-starting-with/${prefix}`;
    return await api.get(fullPath);
  }, 
  async getTosVoyagesByUid(uid) {
    const fullPath = `${apiConfiguration.BASE_PATH_RAIL_GATE}rail-tos/tos-export-voyages/${uid}`;
    return await api.get(fullPath);
  },
  async manualMatch(uid, tosUid, tosVoyage) {
    const fullPath = `${apiConfiguration.BASE_PATH_RAIL_GATE}rail-tos/manual-match/${uid}`;
    return api.post(fullPath, { tosUid: tosUid, tosVoyage: tosVoyage });
  },
  async clearMatch(uid) {
    const fullPath = `${apiConfiguration.BASE_PATH_RAIL_GATE}rail-tos/clear-match/${uid}`;
    return await api.post(fullPath);
  },
  async sendRailRegistration(uid) {
    const fullPath = `${apiConfiguration.BASE_PATH_RAIL_GATE}rail-tos/send-rail-registration/${uid}`;
    return await api.post(fullPath);
  }
};
