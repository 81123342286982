import _ from 'lodash';

const configuration = {
    namespaced: true,
    state: {
        listFilters:{},
        craneSelectedVehicle: {},
        wagonOnFocusRef: {}
    },
    getters: {
        customer(state) {
            return state.customer;
        },

        listFilters: (state) => (key) => {
            return state.listFilters[key];
        },
        craneSelectedVehicle: state => (key) => {
            return state.craneSelectedVehicle[key];
        },
        wagonOnFocusRef: state => (key) => {
            return state.wagonOnFocusRef[key];
        }
    },
    actions: {
        changeConnectionMode({ commit, state }, connMode) {           
      
        },
    },
    mutations: {
        initialize(state){
            state.listFilters={};
            // const lsConfigurationJson = localStorage.getItem('store/configuration');
			// if(lsConfigurationJson) {
            //     alert("Found configuration: "+lsConfigurationJson);
            //     Object.assign(state, JSON.parse(lsConfigurationJson));                				
			// }
        },
        changeConnectionMode(state, connMode) {
        },
        clearFilters(state){
            state.listFilters={};
        },
        setFilters(state, data){
            const key=data.key;
            const filters=data.filters;
            state.listFilters[key]=filters;
        },
        setCraneSelectedVehicle(state, data) {
            const key = data.key;
            const craneSelectedVehicle = data.craneSelectedVehicle;
            state.craneSelectedVehicle[key] = craneSelectedVehicle;
        },
        setWagonOnFocusRef(state, data) {
            const key = data.key;
            const wagonOnFocusRef = data.wagonOnFocusRef;
            state.wagonOnFocusRef[key] = wagonOnFocusRef;
        },
    },
};

export default configuration;