import Vue from 'vue';

const config = {
    environment: {
        produzione: {
            label: 'Prod Environment',
            colorText: 'light-blue lighten-5'
        },
        test: {
            label: 'Test Environment',
            colorText: 'green lighten-5'
        },
        dev: {
            label: 'Dev Environment',
            colorText: 'green lighten-5'
        },
    },
    rails: {
        convoyStates: {
            cancellato: {
                label: 'canc.',
                color: '#CCCCCC',
                descr: 'cancellato',
                status: 'cancellato',
                editMode: false
            },
            creato: {
                label: 'creato',
                color: '#2196F3',
                descr: 'creato',
                status: 'creato',
                editMode: true
            },
            interminal: {
                label: 'gate in',
                color: '#18CA84',
                descr: 'presente in terminal',
                status: 'in terminal',
                editMode: true
            },
            // inlavorazione: {
            //     label: 'lav.',
            //     color: '#FF9800',
            //     descr: 'in lavorazione',
            //     status: 'in lavorazione',
            //     editMode: false
            // },
            prontouscita: {
                label: 'fine lav.',
                color: '#B191EA',
                descr: 'fine lavorazione',
                status: 'pronto uscita',
                editMode: false
            },
            uscitoterminal: {
                label: 'gate out',
                color: '#FFFF00',
                descr: 'uscito dal terminal',
                status: 'uscito dal terminal',
                editMode: false
            }
        },
        utiWagonFeatures: {
            nofeature: {
                color: '#E2E2E2'
            },
            imo: {
                color: '#FFCA28'
            },
            reefer: {
                color: '#00E5FF'
            },
            empty: {
                border: '2'
            },
            tons: {
                text: '29'
            } 
        },
        wagonFeatures: {
            damaged: {
                vuetifycolor: 'red lighten-1',
                color: '#EF5350'
            },
            lowered: {
                vuetifycolor: 'grey darken-4',
                color: '#212121'            
            }
        },
        utiWagonStates: {
            export: {
                pianificato: {
                    label: 'pianificato',
                    color: '#BDBDBD',
                    colorText: 'grey lighten-1',
                    descr: 'pianificato per il carico',
                    status: 'pianificato'
                },
                caricato: {
                    label: 'da scaricare',
                    color: '#00E676',
                    colorText: 'green accent-3',
                    descr: 'da scaricare dal carro',
                    status: 'caricato'
                },
                scaricato: {
                    label: 'scaricato',
                    color: '#C8E6C9',
                    colorText: 'green lighten-4',
                    descr: 'scarico effettuato',
                    status: 'scaricato'
                },
                parzialmenteScaricato: {
                    label: 'parzialmente scaricato',
                    color: '#76FF03',
                    colorText: 'light-green accent-3',
                    descr: 'parzialmente caricato',
                    status: 'parzialmenteScaricato'
                },
            },

            import: {
                pianificato: {
                    label: 'da caricare',
                    color: '#B3E5FC',
                    colorText: 'light-blue lighten-4',
                    descr: 'pianificato per il carico',
                    status: 'pianificato'
                },
                pcheck: {
                    label: 'p-check',
                    color: '#00D0FF',
                    colorText: 'light-blue accent-3',
                    descr: 'p-check (caricato sul carro)',
                    status: 'p-check'
                },
                caricato: {
                    label: 'caricato',
                    color: '#00B0FF',
                    colorText: 'light-blue accent-3',
                    descr: 'caricato sul carro',
                    status: 'caricato'
                },
                parzialmenteCaricato: {
                    label: 'parzialmente caricato',
                    color: '#18FFFF',
                    colorText: 'cyan accent-2',
                    descr: 'parzialmente caricato',
                    status: 'parzialmenteCaricato'
                },
            }
        },
        utiStates: {
            export: {
                charged: {
                    label: 'Arrivo caricato',
                    color: '#36ff8f',
                    descr: 'Arrivo caricato',
                    status: 'caricato',
                    action: null
                },
                uncharged: {
                    label: 'Arrivo scaricato',
                    color: '#cccccc',
                    descr: 'Arrivo scaricato',
                    status: 'scaricato',
                    action: 'scarica'
                },
                reset: {
                    label: 'Reset',
                    color: '#36ff8f',
                    descr: 'Reset stato',
                    status: 'caricato',
                    action: 'reset'
                },
            },
            import: {
                planned: {
                    label: 'Partenza pianificata',
                    color: '#cccccc',
                    descr: 'Partenza pianificata',
                    status: 'scaricato',
                    action: null
                },
                available: {
                    label: 'Partenza disponibile alla pianificazione',
                    color: '#cccccc',
                    descr: 'Partenza disponibile',
                    status: 'disponibile',
                    action: null
                },
                planningAvailable: {
                    label: 'Partenza pre-pianificazione',
                    color: '#cccccc',
                    descr: 'Partenza pre-pianificazione',
                    status: 'pre-pianificazione',
                    action: null
                },
                pCheck: {
                    label: 'Partenza p-check',
                    color: '#00ffff',
                    descr: 'Partenza caricato (p-check)',
                    status: 'p-check',
                    action: 'p-check'
                },
                charged: {
                    label: 'Partenza caricata',
                    color: '#36ff8f',
                    descr: 'Partenza caricata (rail-reg.)',
                    status: 'caricato',
                    action: 'carica'
                },
                reset: {
                    label: 'Reset',
                    color: '#cccccc',
                    descr: 'Reset stato',
                    status: 'scaricato',
                    action: 'reset'
                }
            }
        }
    },
}

Vue.prototype.$config = config;

export { config }