import Vue from 'vue';

const baseOptions = { position: 'top', duration: 5000};
const baseOptionsWarnings = { position: 'top', duration: 10000};
const baseOptionsError = { position: 'top', duration: 60000};

export default {
  methods: {
    showInfo: (msg, options) => {
      Object.assign(baseOptions, options);
      Vue.$toast.info(msg, baseOptions)
    },
    showWarning: (msg, options) => {
      Object.assign(baseOptionsWarnings, options);
      Vue.$toast.warning(msg, baseOptionsWarnings)
    },
    showError: (msg, options) => {
      if (msg.detailMessage) msg=msg.detailMessage;
      else if (msg.message) msg=msg.message;
      Object.assign(baseOptionsError, options);
      Vue.$toast.error(msg, baseOptionsError)
    },
    showSuccess: (msg, options) => {
      Object.assign(baseOptions, options);
      Vue.$toast.success(msg, baseOptions)
    },
  },
};