export default [
  { path: "/yard/inventory/registry/units", view: "yard/inventory/registry/Units", meta: { requiresAuth: true } },
  { path: "/yard/inventory/registry/unittypes", view: "yard/inventory/registry/UnitTypes", meta: { requiresAuth: true } },
  { path: "/yard/inventory/registry/goodstypes", view: "yard/inventory/registry/GoodsTypes", meta: { requiresAuth: true } },
  { path: "/yard/inventory/registry/persons", view: "yard/inventory/registry/Persons", meta: { requiresAuth: true } },
  { path: "/yard/inventory/registry/handlingtypes", view: "yard/inventory/registry/HandlingTypes", meta: { requiresAuth: true } },
  { path: "/yard/inventory/registry/damagetypes", view: "yard/inventory/registry/DamageTypes", meta: { requiresAuth: true } },
  { path: "/yard/inventory/inventoryVisits", view: "yard/inventory/InventoryVisits", meta: { requiresAuth: true } },
  { path: "/yard/inventory/preadvicedInventoryVisits", view: "yard/inventory/PreadvicedInventoryVisits", meta: { requiresAuth: true } },
  { path: "/yard/inventory/createPreadvice", view: "yard/inventory/CreatePreadvice", meta: { requiresAuth: true } },
  {
    path: "/yard/inventory/truckInventoryVisits",
    view: "yard/inventory/TruckInventoryVisits",
    meta: { requiresAuth: true },
  },
  {
    name: "EditTransits",
    path: "/yard/inventory/editTransits/:truckVisitId",
    view: "yard/inventory/EditTransits",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/yard/inventory/gateOutInventoryVisits",
    view: "yard/inventory/GateOutInventoryVisits",
    meta: { requiresAuth: true },
  },
  {
    path: "/yard/inventory/transits",
    view: "yard/inventory/Transits",
    meta: { requiresAuth: true },
  },
  { path: "/yard/inventory/visits", view: "yard/inventory/Visits", meta: { requiresAuth: true } },
  { path: "/yard/inventory/allVisits", view: "yard/inventory/AllVisits", meta: { requiresAuth: true } },
  { path: "/yard/inventory/allVisits2", view: "yard/inventory/AllVisits2", meta: { requiresAuth: true } },
  { path: "/yard/inventory/allVisitsInfinite", view: "yard/inventory/allVisitsInfinite", meta: { requiresAuth: true } },
  { path: "/yard/inventory/unitvisits", view: "yard/inventory/UnitVisits", meta: { requiresAuth: true } },
  { path: "/yard/inventory/handlings", view: "yard/inventory/Handlings", meta: { requiresAuth: true } },
  { path: "/yard/inventory/visitDetails/:visitId/:visitSysType", view: "yard/inventory/VisitDetails", props: true, meta: { requiresAuth: true } },
  { path: "/yard/inventory/operations", view: "yard/inventory/Operations", meta: { requiresAuth: true } },
  { path: "/yard/inventory/visit_positions", view: "yard/inventory/VisitPositions", meta: { requiresAuth: true } },
  
  { 
    path: "/yard/inventory/stuffing", 
    name: "Stuffing",
    view: "yard/inventory/Stuffing", 
    props: (route) => ({
      ...route.params
    }),
    meta: { requiresAuth: true } 
  },
  { 
    path: "/yard/inventory/stuffing/manage", 
    name: "CreateStuffingOrder",
    view: "yard/inventory/ManageStuffingOrder", 
    meta: { requiresAuth: true } 
  },
  { 
    path: "/yard/inventory/stuffing/manage/:orderId", 
    name: "EditStuffingOrder", 
    view: "yard/inventory/ManageStuffingOrder", 
    props: (route) => ({
      ...route.params
    }),
    meta: { requiresAuth: true } 
  },
  { 
    path: "/yard/inventory/stuffingOperation/:operationId", 
    name: "StuffingOperation",
    view: "yard/inventory/StuffingOperation", 
    props: (route) => ({
      ...route.params
    }),
    meta: { requiresAuth: true } 
  },
  
  { 
    path: "/yard/inventory/stripping", 
    name: "Stripping",
    view: "yard/inventory/Stripping", 
    props: (route) => ({
      ...route.params
    }),
    meta: { requiresAuth: true } 
  },
  { 
    path: "/yard/inventory/stripping/manage", 
    name: "CreateStrippingOrder",
    view: "yard/inventory/ManageStrippingOrder", 
    meta: { requiresAuth: true } 
  },
  { 
    path: "/yard/inventory/stripping/manage/:orderId", 
    name: "EditStrippingOrder", 
    view: "yard/inventory/ManageStrippingOrder", 
    props: (route) => ({
      ...route.params
    }),
    meta: { requiresAuth: true } 
  },
  { 
    path: "/yard/inventory/strippingOperation/:operationId", 
    name: "StrippingOperation",
    view: "yard/inventory/StrippingOperation", 
    props: (route) => ({
      ...route.params
    }),
    meta: { requiresAuth: true } 
  },
];
