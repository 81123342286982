import api from "../api";
import apiConfiguration from "../apiConfiguration";
import _ from "lodash";

export default {
  async getParameters() {
    return await api.get(apiConfiguration.BASE_PATH_REGISTRY + "parameters");
  },

};
