<template>
  <!-- src="assets/user-profile-bg.jpg" -->
  <v-app-bar app clipped-left color="secondary" dark>
    <v-app-bar-nav-icon @click.stop="onchangeDrawer"></v-app-bar-nav-icon>
    <!-- <v-img :src="pathLogo" max-height="50" max-width="50" /> -->
    <v-toolbar-title>{{ appName }}</v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      customer: process.env.VUE_APP_CUSTOMER || "logoRail",
    };
  },
  computed: {
    pathLogo() {
      let basePath = "assets/";
      let img = this.customer + ".jpg";
      return basePath + this.customer + "/" + img;
    },
  },

  props: {
    value: Boolean,
  },
  methods: {
    onchangeDrawer() {
      const value = !this.value;
      this.$emit("input", value);
    },
  },
};
</script>
