import Vue from 'vue';
import moment from 'moment';

const authentication = {
    namespaced: true,
    state: {
        status: 'not logged in',
        token: localStorage.getItem('authToken') || '',
        loggedUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
    },
    getters : {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        authToken: state => state.token,
        loggedUser: state => state.loggedUser
    },
    actions: {
        async login({commit}, user) {

            return new Promise((resolve, reject) => {
              commit('auth_request');
              Vue.api.usersManagement.login(user)
                .then(response => authentication._doLoginResponse(response,commit,resolve))
                .catch(error => authentication._doLoginErrorResponse(error, commit, reject));
            });
        },
        async sso({commit}, loginToken) {
          return new Promise((resolve, reject) => {
            commit('auth_request');
            Vue.api.usersManagement.sso(loginToken)
            .then(response => authentication._doLoginResponse(response,commit,resolve))
            .catch(error => authentication._doLoginErrorResponse(error, commit, reject));
      });
      },
      logout({commit}){
            localStorage.removeItem('authToken');
            localStorage.removeItem('currentUser');
            commit('logout');            
      }
    },
    mutations: {
        auth_request(state){
          state.status = 'login requesting';
        },
        auth_success(state, { token, loggedUser }){
          state.status = 'logged in';
          state.token = token;
          state.loggedUser = loggedUser;
        },
        auth_renew_request(state){
          state.status = 'refresh token requesting';
        },
        auth_renewed(state, { token, loggedUser }){
          state.status = 'logged in';
          state.token = token;
          state.loggedUser = loggedUser;
        },
        auth_error(state){
          state.status = 'login refused';
          state.token = '';
        },
        logout(state){
          state.status = 'not logged in';
          state.token = '';
          state.loggedUser = null;
        },
      },
      _doLoginResponse(response, commit, resolve){
        //TODO : uniformare risposta users-manager e rails rest
        console.log("RISPOSTA TOKEN", response.token)
        const token = response.token;
        const loggedUser = ( response.user !=null) ? { //users-manager
          username: response.user.username ,
          roles: response.user.groups.map(g=> g.code), 
          loggedInAt: moment().format("DD/MM/YYYY HH:mm")
        } : {  //rails rest
          username: response.username ,
          roles: response.roles,
          loggedInAt: moment().format("DD/MM/YYYY HH:mm")
        } ;
        console.log("loggedUser", loggedUser);
        localStorage.setItem('authToken', token);
        localStorage.setItem('currentUser', JSON.stringify(loggedUser));
        localStorage.removeItem("railsWeekCalendarParkFilter");
        localStorage.removeItem("railsWeekCalendarStartDateFilter");
        commit('auth_success', { 
          token, 
          loggedUser
        });                        
        resolve();
      },
      _doLoginErrorResponse(error,commit,reject){
        commit('auth_error');
        localStorage.removeItem('authToken');
        reject(error);
      },
};

export default authentication;