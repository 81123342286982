export default [
  {
    path: "/login",
    name: "login",
    view: "Login",
  },
  {
    path: "/",
    name: "home",
    view: "Home",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/utenti",
    name: "utenti",
    view: "anagrafiche/Utenti",
    meta: { requiresAuth: true },
  },
  {
    path: "/audit",
    name: "audit",
    view: "yard/inventory/Audit",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/carri",
    name: "carri",
    view: "anagrafiche/Carri",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/tipoCarri",
    name: "tipoCarri",
    view: "anagrafiche/TipoCarri",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/uti",
    name: "uti",
    view: "anagrafiche/Uti",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/tipiUti",
    name: "tipiUti",
    view: "anagrafiche/TipiUti",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/nodi",
    name: "nodi",
    view: "anagrafiche/Nodi",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/tracce",
    name: "tracce",
    view: "anagrafiche/Tracce",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/motivazioniRifiuto",
    name: "motivazioniRifiuto",
    view: "anagrafiche/MotivazioniRifiuto",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/danni",
    name: "danni",
    view: "anagrafiche/Danni",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/tipiSigilli",
    name: "sigilli",
    view: "anagrafiche/TipiSigilli",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/tipiMezzoMovimentazione",
    name: "tipiMezzoMovimentazione",
    view: "anagrafiche/TipiMezzoMovimentazione",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/mezziMovimentazione",
    name: "mezziMovimentazione",
    view: "anagrafiche/MezziMovimentazione",
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/viaggiTreno/:faseOperativa",
    name: "callTreno",
    view: "callTreno/ViaggiTreno",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/viaggiTrenoPartenze/:faseOperativa",
    name: "viaggiTrenoPartenze",
    view: "callTreno/ViaggiTrenoPartenze",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/viaggiTrenoArrivi/:faseOperativa",
    name: "viaggiTrenoArrivi",
    view: "callTreno/ViaggiTrenoArrivi",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/Convogli/:faseOperativa",
    name: "convogli",
    view: "rails/Convogli",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/pianificazioneViaggioArrivo/:viaggioTrenoId",
    name: "pianificazioneViaggioArrivo",
    view: "callTreno/PianificazioneViaggioArrivo",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/pianificazioneViaggioPartenza/:tripId",
    name: "pianificazioneViaggioPartenza",
    view: "callTreno/PianificazioneViaggioPartenza",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/lavorazioneViaggioArrivo/:viaggioTrenoId",
    name: "lavorazioneViaggioArrivo",
    view: "callTreno/LavorazioneViaggioArrivo",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/lavorazioneCarroConvoglio/:carroConvoglioId",
    name: "lavorazioneCarroConvoglio",
    view: "callTreno/LavorazioneCarroConvoglio",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/lavorazioneCaricoCarroConvoglio/:convoyId/:convoyWagonId",
    name: "lavorazioneCaricoCarroConvoglio",
    view: "callTreno/LavorazioneCaricoCarroConvoglio",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/lavorazioneCaricoCarroConvoglio/:convoyId/:convoyWagonId/:voyageWagonId",
    name: "lavorazioneCaricoCarroConvoglioByWagons",
    view: "callTreno/LavorazioneCaricoCarroConvoglio",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/caricoCarroConvoglio/:carroConvoglioId",
    name: "caricoCarroConvoglio",
    view: "callTreno/CaricoCarroConvoglio",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/lavorazioneConvoglioArrivo/:convoglioId",
    name: "lavorazioneConvoglioArrivo",
    view: "callTreno/LavorazioneConvoglioArrivo",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/lavorazioneConvoglioPartenza/:convoyId/:voyageId",
    name: "lavorazioneConvoglioPartenzaWithVoyage",
    view: "callTreno/LavorazioneConvoglioPartenza",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/lavorazioneConvoglioPartenza/:convoyId",
    name: "lavorazioneConvoglioPartenza",
    view: "callTreno/LavorazioneConvoglioPartenza",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/convoyAssociaDanni/:carroConvoglioId/:utiCarroId/:tipoDanno",
    name: "convoyAssociaDanni",
    view: "callTreno/ConvoyAssociaDanni",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/convoyAssociaSigilli/:carroConvoglioId/:tipoSigillo/:visitaUtiId/:codiceUti",
    name: "convoyAssociaSigilli",
    view: "callTreno/ConvoyAssociaSigilli",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/callTreno/lavorazioneViaggioPartenza/:tripId",
    name: "lavorazioneViaggioPartenza",
    view: "callTreno/LavorazioneViaggioPartenza",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/parcoBinari",
    name: "parcoBinari",
    view: "anagrafiche/ParcoBinari",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/binari/:parcoBinariId",
    name: "binari",
    view: "anagrafiche/Binari",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/soggetti",
    name: "soggetti",
    view: "anagrafiche/Soggetti",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/locode",
    name: "locode",
    view: "anagrafiche/Locode",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/nazioni",
    name: "nazioni",
    view: "anagrafiche/Nazioni",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/visiteUti",
    name: "visiteUti",
    view: "anagrafiche/VisiteUti",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/visiteCarri",
    name: "visiteCarri",
    view: "anagrafiche/VisiteCarri",
    meta: { requiresAuth: true },
  },
  {
    path: "/anagrafiche/turni",
    name: "turni",
    view: "anagrafiche/Turni",
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/pianificazioneTreno",
    name: "pianificazioneTreno",
    view: "rails/PianificazioneTreno",
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/RailsWeekCalendar",
    name: "railsWeekCalendar",
    view: "rails/RailsWeekCalendar",
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/Now",
    name: "now",
    view: "rails/Now",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/RealTimeVisualization",
    name: "realTimeVisualization",
    view: "rails/RealTimeVisualization",
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/Manovre",
    name: "manovre",
    view: "rails/Manovre",
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/manovre/ComposizioneTrenoPartenza/:tripId",
    name: "ComposizioneTrenoPartenza",
    view: "rails/manovre/ComposizioneTrenoPartenza",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/ConvoyVisualization/:convoyId",
    name: "convoyVisualization",
    view: "rails/ConvoyVisualization",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/ConvoyExportVisualization/:convoyId",
    name: "convoyExportVisualization",
    view: "rails/ConvoyExportVisualization",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/ConvoyImportVisualization/:convoyId",
    name: "convoyImportVisualization",
    view: "rails/ConvoyImportVisualization",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/occupazioneBinari",
    name: "occupazioneBinari",
    view: "rails/OccupazioneBinari",
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/gate",
    name: "railGate",
    view: "rails/gate/Gate",
    meta: { requiresAuth: true },
  },
  {
    path: "/rails/gate",
    name: "railGateFrom",
    view: "rails/gate/Gate",
    props: (route) => ({
        ...route.params
    }),
    meta: { requiresAuth: true }
  },
  {
    path: "/rails/gate/trainDetail/:uid",
    name: "railGateTrainDetail",
    view: "rails/gate/TrainDetail",
    props: (route) => ({
      ...route.params
    }),
    meta: { requiresAuth: true },
  },
  {
    path: "/lavorazioni",
    name: "lavorazioni",
    view: "lavorazioni/lavorazioni",
    meta: { requiresAuth: true },
  },
  {
    path: "/lavorazioni/:id",
    name: "lavorazioni/lavora",
    view: "lavorazioni/lavora",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/creaLavorazione",
    name: "lavorazioni/CreaLavorazione",
    view: "lavorazioni/CreaLavorazione",
    meta: { requiresAuth: true },
  },
  // {
  //     path: '/lavorazioni/dettagli',
  //     name: 'lavorazioni/dettagli',
  //     view: 'lavorazioni/dettagli',
  //     meta: { requiresAuth: true }
  // },
  {
    path: "/filesList",
    name: "filesList",
    view: "misc/FilesList",
    meta: { requiresAuth: true },
  },
  {
    path: "/filesList/:codice",
    name: "filesListByCodice",
    view: "misc/FilesList",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/configurazione",
    name: "configurazione",
    view: "misc/Configurazione",
    meta: { requiresAuth: true },
  },
  { path: "/registry/unittypes", view: "registry/UnitTypes", meta: { requiresAuth: true } },
  { path: "/registry/goodstypes", view: "registry/GoodsTypes", meta: { requiresAuth: true } },
  { path: "/registry/damagetypes", view: "registry/DamageTypes", meta: { requiresAuth: true } },
  { path: "/registry/locode", view: "registry/Locode", meta: { requiresAuth: true } },
  { path: "/registry/countries", view: "registry/Countries", meta: { requiresAuth: true } },
  { path: "/registry/companies", view: "registry/Companies", meta: { requiresAuth: true } },
  { path: "/registry/parameters", view: "registry/Parameters", meta: { requiresAuth: true } },
  {
    path: "*",
    name: "notFound",
    view: "Documentation",
    props: true,
    // meta: { requiresAuth: true },
    // children: [
    //     {
    //         path: '*',
    //         name: 'notFound',
    //         view: 'Documentation',
    //         props: true,
    //     }
    // ]
  },
  { path: "/upload-csv", view: "CsvImporter", props: false, meta: { requiresAuth: true } },
  { path: "/sso/", view: "Login" },
  { path: "/sso/*", view: "Sso", props: true, meta: { requiresAuth: false } },
  {
    path: "/anagrafiche/destinatariReport",
    name: "destinatariReport",
    view: "anagrafiche/DestinatariReport",
    meta: { requiresAuth: true },
  },
  {
    path: "/block/blockRules",
    name: "blockRules",
    view: "block/BlockRules",
    meta: { requiresAuth: false },
  },
  {
    path: "/block/blocks",
    name: "blocks",
    view: "block/Blocks",
    meta: { requiresAuth: false },
  },
];
