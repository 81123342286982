import _ from 'lodash'; 

const state = {
    rails: [],
};

const mutations = {
    updateRailList(state, payload) {
        state.rails = payload;
    },
};

const actions = {
    updateRailList({ commit }, rails) {
        _.forEach(rails, (r) => {
            _.forEach(r.trains, (t) => {
                t.start = t.start / 24 * 100;
                t.end = t.end / 24 * 100
            });        
        });
        commit('updateRailList', rails);
    },
};

const getters = {
    rails(state) {
        return state.rails;
    },
    railNumber(state) {
        return state.rails.length;
    },
    trainNumber(state) {
        let count = 0;
        _.forEach(state.rails, (r) => {
            if(r.trains === Array){
                count += r.trains.length;
            }
        });
        return count;       
    },
    trainNumberPerRail: (state) => (rail) => {
        const frail = _.find(state.rails, (r) => {
            return r.id === rail.id;
        });
        return frail.trains.length;
    }
};

const trainSchedulation = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default trainSchedulation;