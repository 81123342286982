import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';
import paths_yard from './paths_yard';
import paths_report from './paths_report';
import store from '../store';

Vue.use(Router);

function route(path, view, name, props, meta, children) {
    const routeObj = {
        name: name || view,
        path,
        component: () =>
            import (`@/views/${view}.vue`),
        props: props,
        meta: meta
    };

    if (children && children.length) {
        routeObj.children = [];
        for (let i = 0; i < children.length; i++) {
            let child = children[i];
            routeObj.children.push(
                route(child.path, child.view, child.name, child.props, child.meta, child.children));
        }
    }
    return routeObj;
}

const router = new Router({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes: paths.concat(paths_yard).concat(paths_report).map((path) =>
        route(path.path, path.view, path.name, path.props, path.meta, path.children)),

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    // console.log("to   -------------> ", to);
    // console.log("from -------------> ", from);
    // console.log("next -------------> ", next);
    if (to.matched.some(record => record.name === 'login')) {
        store.commit('app/menuVisibility', false);
        next();
        return;
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters['authentication/isLoggedIn']) {
            store.commit('app/menuVisibility', false);
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
            return;
        }
        store.commit('app/menuVisibility', true);
        next();
        return;
    }
    store.commit('app/menuVisibility', true);
    next();
});

export default router;