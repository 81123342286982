import { set, toggle } from '../utils';
import menuTree from '../menuTree';

const app = {
    namespaced: true,
    state: {
        drawer: null,
        image: '',
        color: '',
        isLoading: false,
        isMenuVisible: false,
        refCount: 0,
        menuTree: menuTree,
    },
    getters : {
      isLoggedIn: state => !!state.token,
      authStatus: state => state.status,
      menuTree(state) {
          //getting user data from root state it's possible to charge a different menu tree
          return state.menuTree;
      },
    },
    mutations: {
        setDrawer: set('drawer'),
        setImage: set('image'),
        setColor: set('color'),
        toggleDrawer: toggle('drawer'),
        loading(state, isLoading) {
          if (isLoading) {
            state.refCount++;
            state.isLoading = true;
          } else if (state.refCount > 0) {
            state.refCount--;
            state.isLoading = (state.refCount > 0);
          }
        },
        menuVisibility: set('isMenuVisible')
    },
  };

export default app;