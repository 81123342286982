import api from '../api';
import apiConfiguration from '../apiConfiguration';

export default {
	login: (user) => {
		const fullPath = `${apiConfiguration.BASE_PATH_AUTHENTICATION}authentication`;
		return api.post(fullPath, user);
	},
	sso: (loginToken) => {
		const fullPath = `${apiConfiguration.BASE_PATH_AUTHENTICATION}authentication/sso`;
		console.log("LOGIN TOKEN: "+loginToken)
		return api.post(fullPath, loginToken, {
			headers: {
				'Content-Type': 'application/text',
				'Accept': 'application/json',
				'Pragma': 'no-cache'
			}

		});
	},
}