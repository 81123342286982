import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import configuration from './modules/configuration';
import authentication from './modules/authentication';
import trainSchedulation from './modules/trainSchedulation';
//import pianificazioneArrivo from './modules/pianificazioneArrivo';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    language: 'it'
  },
  mutations: {
    changeLocale(state, langCode) {
      state.language = langCode;
    }
  },
  actions: {
    changeLocale(context, langCode) {
      context.commit('changeLocale', langCode);
    }
  },
  modules: {
    app: app,
    configuration: configuration,
    authentication: authentication,
    trainSchedulation: trainSchedulation,
//    pianificazioneArrivo: pianificazioneArrivo,
  }
});

store.subscribe((mutation, state) => {
  if(mutation.type.startsWith('configuration')){
    localStorage.setItem('store/configuration', JSON.stringify(state.configuration));
  }
});

export default store;