import axios from 'axios';
import store from '../../store/';
import router from '../../router';
  
const _callConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Pragma': 'no-cache'
    }
}

function call(url, type, body, configParams) {
    let callConfig = {};
    Object.assign(callConfig, _callConfig);
    if(configParams){
        Object.assign(callConfig, configParams);
    }
    if(store.getters['authentication/isLoggedIn']) {
        callConfig.headers.Authorization = `Bearer ${store.getters['authentication/authToken']}`;
    }  

    const promise = new Promise((resolve, reject) => {
        store.commit('app/loading', true);
        let httpMethod;
        switch (type) {
            case 'get':
                httpMethod = axios.get(url, callConfig);
                break
            case 'post':
                httpMethod = axios.post(url, body, callConfig);
                break
            case 'put':
                httpMethod = axios.put(url, body, callConfig);
                break
            case 'delete':
                httpMethod = axios.delete(url, callConfig);
                break;
            default:
                httpMethod = axios.get(url, callConfig);
                break
        }

        httpMethod.then(response => {
            resolve(response.data)
            store.commit('app/loading', false);
        },
        error => {
            store.commit('app/loading', false);
            let message;
            let detailMessage = '';
            if(error.response && error.response.data){
                detailMessage = error.response.data.message;
            } else {
                let response={message: error.message, detailMessage:error.message };
                console.log(response);
                reject(response);
            }
            const status= error.response? error.response.status : 500;
            const data=error.response? error.response.data: null;
            switch (status) {
                case 401:
                case 403: {
                    store.dispatch('authentication/logout');
                    message = 'Unauthorized error response';
                    router.push({ path: '/login' });
                    break;
                }
                case 400:
                    message = `Bad request error response: ${detailMessage}`;
                    break;
                case 404:
                    message = 'Resource not found';
                    break;
                case 500:
                    message = `Internal server error response : ${detailMessage}`;
                    break;
                default:
                    message = 'Unmanaged server error response';
                    break;
            }
            const response={message: message, detailMessage:detailMessage, status:status,  data:data };
            console.log(response);
            reject(response);
        })
    });
    return promise;
}

export default {
    get: (url, configParams) => call(url, 'get', null, configParams),
    put: (url, body, configParams) => call(url, 'put', body, configParams),
    post: (url, body, configParams) => call(url, 'post', body, configParams), 
    delete: (url, configParams) => call(url, 'delete', null, configParams)
}