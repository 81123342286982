import api from "../api";
import apiConfiguration from "../apiConfiguration";
import _ from "lodash";

export default {
  async getLookups() {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "lookups/all");
  },

  async getLookupsBlocks() {
    return await api.get(apiConfiguration.BASE_PATH_BLOCK + "lookups/all");
  },

  async forceResolveBlock(id) {
    return await api.post(apiConfiguration.BASE_PATH_BLOCK + "blocks/force-resolve", id);
  },

  async getInventoryItems(filters) {
    console.log("filters", filters);
    return await api.get(apiConfiguration.BASE_PATH_YARD + "inventoryVisits/list", { params: filters });
  },

  async getPreadviceItems(filters) {
    console.log("filters", filters);
    return await api.get(apiConfiguration.BASE_PATH_YARD + "preadvice/list", { params: filters });
  },

  async gateIn(reqObj) {
    console.log("dentro gate in in yardData");
    return await api.post(apiConfiguration.BASE_PATH_YARD + "gate/gateIn", reqObj);
  },

  async gateOut(reqObj) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "gate/gateOut", reqObj);
  },

  async checkPickUpCarrier(reqObj) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "gate/checkPickUpCarrier", reqObj);
  },

  async checkPickUpCarrierForGoods(reqObj) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "gate/checkPickUpCarrierForGoods", reqObj);
  },

  async replaceCarrierGateOut(reqObj) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "gate/replaceCarrierGateOut", reqObj);
  },

  async prepareGateOut(visitId) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "gate/prepareGateOut/" + visitId);
  },

  async getMissionByPlate(plate){
    return await api.get(apiConfiguration.BASE_PATH_YARD + "tos/gate/itc-by-plate/" + plate);
  },
  async gateOutMission(mission) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "tos/gate/gate-out", mission);
  },

  async listTrainVoyages() {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "gate/listTrainVoyages");
  },

  async listWagonLicensePlates(voyageId) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "gate/listWagonLicensePlates/" + voyageId);
  },

  async goodsTypeCodes() {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "goods-types/");
  },
  async damageTypes() {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "damage-types/");
  },

  async preadviceSubmit(reqObj) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "preadvice/submit", reqObj);
  },

  async preadviceVisitUnit(unitCode) {
    if (!unitCode) {
      return null;
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "preadvice/prepare-by-unit-code/" + unitCode);
  },

  async getPreadvicedUnit(unitCode) {
    if (!unitCode) {
      return null;
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "gate/preadviced/" + unitCode);
  },

  async preadvicePlate(plate) {
    if (!plate) {
      return null;
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "preadvice/prepare-by-plate/" + plate);
  },

  async getRoroVisitsInYardOrEntering() {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "rorovisits/list", { params: { filters: { inYardAndEntering: true } } });
  },

  async getRoroVisitsByLicensePlateStartsWithIgnoringCaseAndInYardOrEntering(licensePlate) {
    if (!licensePlate) {
      return null;
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "rorovisits/list", { params: { filters: { objectCodeStartsWithIgnoringCase: licensePlate, inYardAndEntering: true } } });
  },

  async getRoroVisitById(roroVisitId) {
    if (!roroVisitId) {
      return null;
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "rorovisits/" + roroVisitId);
  },

  async getRoroVisitByLicensePlate(licensePlate) {
    if (!licensePlate) {
      return null;
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "rorovisits/list", { params: { filters: { objectCode: licensePlate } } });
  },

  async getPersonVisitsByPrimaryId(primaryId) {
    if (!primaryId) {
      return [];
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "personvisits/getByPrimaryVisitId/" + primaryId);
  },

  async getVisitsByPrimaryId(primaryId) {
    if (!primaryId) {
      return [];
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "visits/getByPrimaryVisitId/" + primaryId);
  },

  async removePreadviceItem(itemId) {
    if (!itemId) {
      return;
    }
    await api.post(apiConfiguration.BASE_PATH_YARD + "preadvice/remove", itemId);
  },

  async getHandlingById(id) {
    if (!id) {
      return {};
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "handlings/" + id);
  },

  async getHandlingsByVisitId(visitId) {
    if (!visitId) {
      return [];
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "handlings/getByVisitId/" + visitId);
  },

  compareHandlingsByDate(a, b) {
    if (a.execDate) {
      if (b.execDate) {
        if (a.execDate < b.execDate) {
          return -1;
        }
        if (a.execDate > b.execDate) {
          return 1;
        }
      } else {
        if (a.execDate < b.plannedDate) {
          return -1;
        }
        if (a.execDate > b.plannedDate) {
          return 1;
        }
      }
    } else {
      if (b.execDate) {
        if (a.plannedDate < b.execDate) {
          return -1;
        }
        if (a.plannedDate > b.execDate) {
          return 1;
        }
      } else {
        if (a.plannedDate < b.plannedDate) {
          return -1;
        }
        if (a.plannedDate > b.plannedDate) {
          return 1;
        }
      }
    }
    return 0;
  },

  async getHandlingByVisitIdAndDirection(visitId, direction) {
    if (!visitId) {
      return {};
    }
    if (direction === "IN") {
      return await api.get(apiConfiguration.BASE_PATH_YARD + "handlings/getInByTruckAndVisitId/" + visitId);
    } else if (direction === "OUT") {
      return await api.get(apiConfiguration.BASE_PATH_YARD + "handlings/getOutByTruckAndVisitId/" + visitId);
    }
  },

  async getVisitById(id) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "visits/" + id);
  },

  async updateVisitWithDamages(visit, sysType) {
    var sysTypePath = "visits";
    var restPath = "edit";
    switch (sysType) {
      case "UNT":
        sysTypePath = "unitvisits";
        restPath = "updateWithDamages";
        break;
      case "GOO":
        sysTypePath = "goodsvisits";
        restPath = "updateWithDamages";
        break;
      case "RRO":
        sysTypePath = "rorovisits";
        break;
      case "PER":
        sysTypePath = "personvisits";
        break;
      case "WAG":
        sysTypePath = "wagonvisits";
        break;
      case "EQU":
        sysTypePath = "equipmentvisits";
        break;
      case "VES":
        sysTypePath = "vesselvisits";
        break;
      default:
        sysTypePath = "visits";
        restPath = "edit";
        break;
    }

    console.log("updateVisitWithDamages visit --- ", apiConfiguration.BASE_PATH_YARD + sysTypePath + "/" + restPath, visit);
    return await api.post(apiConfiguration.BASE_PATH_YARD + sysTypePath + "/" + restPath, visit);
  },

  async getVisitDamagesByVisitId(visitId) {
    if (!visitId) {
      return {};
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "damages/getByVisitId/" + visitId);
  },
  async getAudit(id) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "audits/" + id);
  },

  getIconMap() {
    var iconMap = {};
    iconMap["UNT"] = "mdi-view-week-outline";
    iconMap["EQU"] = "mdi-forklift";
    iconMap["INT"] = "mdi-forklift";
    iconMap["BUL"] = "mdi-tanker-truck";
    iconMap["RRO"] = "mdi-truck";
    iconMap["TRU"] = "mdi-truck";
    iconMap["PER"] = "mdi-walk";
    iconMap["WAG"] = "mdi-train";
    iconMap["TRA"] = "mdi-train";
    iconMap["VES"] = "mdi-ferry";
    iconMap["MSC"] = "mdi-warehouse";
    iconMap["YARD"] = "mdi-warehouse";
    iconMap["IN"] = "mdi-import";
    iconMap["OUT"] = "mdi-export";
    iconMap["GOO"] = "mdi-dolly";
    return iconMap;
  },

  async updateVisit(visit, sysType) {
    var sysTypePath = "visits";
    switch (sysType) {
      case "UNT":
        sysTypePath = "unitvisits";
        break;
      case "GOO":
        sysTypePath = "goodsvisits";
        break;
      case "RRO":
        sysTypePath = "rorovisits";
        break;
      case "PER":
        sysTypePath = "personvisits";
        break;
      case "WAG":
        sysTypePath = "wagonvisits";
        break;
      case "EQU":
        sysTypePath = "equipmentvisits";
        break;
      case "VES":
        sysTypePath = "vesselvisits";
        break;
      default:
        sysTypePath = "visits";
        break;
    }
    console.log("path", apiConfiguration.BASE_PATH_YARD + sysTypePath + "/" + visit.id);
    return await api.post(apiConfiguration.BASE_PATH_YARD + sysTypePath + "/edit", visit);
  },

  async getItineraryByVisitId(visitId) {
    if (!visitId) {
      return [];
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "itineraries/getByVisitId/" + visitId);
  },

  async updateItineraryVisit(itineraryVisit) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "itineraries/edit", itineraryVisit);
  },

  async updateHandlings(handlings) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "handlings/edit", handlings);
  },

  async getHandlingTypes() {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "handlingtypes");
  },

  async getUnitHandlingsByCarrierId(carrierId) {
    if (!carrierId) {
      return [];
    }
    return await api.get(apiConfiguration.BASE_PATH_YARD + "handlings/getHandlingsByCarrierIdAndRole/" + carrierId + "/UNT");
  },

  async searchDrivers(val) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "preadvice/search-drivers/" + val);
  },

  async checkAction(name, visitsId) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "visits/check-action/" + name + "?visitsId=" + visitsId);
  },
  async checkActionAll(name, filters) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "visits/check-action/" + name + "?filters=" + filters);
  },
  async execAction(name, request) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "visits/exec-action/" + name, request);
  },
  async getCarrierVisitsExpected() {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "tos/gate/carrier-visits-expected");
  },
  async enterCarrierVisit(carrierVisit) {
    await api.post(apiConfiguration.BASE_PATH_YARD + "tos/gate/enter-carrier-visit/" + carrierVisit);
  },
  async getVisitsPositions() {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "visits/visit-positions");
  },
  async setVisitsPositions(list) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "visits/visit-positions", list);
  },

  async getOrder(orderType, orderId) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "orders/" + orderType + "/" + orderId);
  },

  async getOrders(orderType, completed, searchValue, length, start, sortField, sortOrder) {
    var path = apiConfiguration.BASE_PATH_YARD + "orders/" + orderType + (completed ? "/completed" : "") + "/";
    // console.log("getOrders --->", orderType, completed, searchValue, length, start, sortField, sortOrder);
    var params = {};
    if (searchValue && searchValue !== null && searchValue !== "") {
      params.filter = searchValue;
    }
    if (length) {
      params.length = length;
    }
    if (start) {
      params.start = start - 1;
    }
    if (sortField && sortField !== null) {
      params.sortField = sortField;
    }
    if (sortOrder && sortOrder !== null) {
      params.sortOrder = sortOrder;
    }

    if (Object.keys(params).length > 0) {
      path += "?";
      Object.keys(params).forEach((key) => {
        path += key + "=" + params[key] + "&";
      });
      path = path.substring(0, path.length - 1);
    }
    // console.log("PATH!!!!!!!!!!!!! --->", params, path);
    return await api.get(path);
  },

  async getOrderByReference(reference) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "orders/by-reference/" + reference);
  },

  async getUnitsNotExited(orderType) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "orders/" + orderType + "/units-not-exited");
  },

  async getGoodsNotExitedAndNotInOrders() {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "orders/goods-not-exited-not-in-order");
  },

  async getOperationById(operationId) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId);
  },

  async stuff(operationId, goodsToStuff) {
    console.log("stuff", operationId, goodsToStuff);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/stuff", goodsToStuff);
  },

  async unstuff(operationId, goodsToUnstuff) {
    console.log("unstuff", operationId, goodsToUnstuff);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/unstuff", goodsToUnstuff);
  },

  async strip(operationId, goodsToStrip) {
    console.log("strip", operationId, goodsToStrip);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/strip", goodsToStrip);
  },

  async unstrip(operationId, goodsToUnstrip) {
    console.log("unstuff", operationId, goodsToUnstrip);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/undo-strip", goodsToUnstrip);
  },

  async createUpdateOrder(order) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "orders/", order);
  },

  async closeUnit(operationId, unit) {
    console.log("closeUnit", operationId, unit);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/close-unit", unit);
  },

  async uncloseUnit(operationId) {
    console.log("uncloseUnit", operationId);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/unclose-unit");
  },

  async openUnit(operationId) {
    console.log("openUnit", operationId);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/open-unit");
  },

  async undoOpenUnit(operationId) {
    console.log("undoOpenUnit", operationId);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/undo-open-unit");
  },

  async addGoods(operationId, goods) {
    console.log("addGoods", operationId, goods);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/add-goods", goods);
  },

  async createGoods(operationId, goods) {
    console.log("createGoods", operationId, goods);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/create-goods", goods);
  },

  async removeGoods(operationId, goodsVisitId) {
    console.log("removeGoods", operationId, goodsVisitId);
    return await api.delete(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/remove-good/" + goodsVisitId);
  },

  async setUnit(operationId, unit) {
    console.log("setUnit", operationId, unit);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/set-unit", unit);
  },

  async finishStripping(operationId) {
    console.log("finishStripping", operationId);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/finish-stripping");
  },

  async undoFinishStripping(operationId) {
    console.log("undoFinishStripping", operationId);
    return await api.post(apiConfiguration.BASE_PATH_YARD + "order-operations/" + operationId + "/undo-finish-stripping");
  },

  async setVisitWeight(visitId, unitWeight) {
    return await api.post(apiConfiguration.BASE_PATH_YARD + "visits/weight/" + visitId, unitWeight);
  },

  async getTruckTransits() {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "gate/truck-transits");
  },

  async getTruckTransitByVisitId(visitId) {
    return await api.get(apiConfiguration.BASE_PATH_YARD + "gate/truck-transit/"+visitId);
  },

  async getGoodsForSearchAndSelection(searchFilter, type, page, itemsPerPage) {
    switch (type) {
      default:
        let path = apiConfiguration.BASE_PATH_YARD + "gate/goods-in-yard-without-handlings-out?page=" + (page-1) + "&size=" + itemsPerPage;
        if (searchFilter && searchFilter !== null && searchFilter !== "") {
          path += ("&filter=" + searchFilter);
        }
        console.log("getGoodsForSearchAndSelection path", path);
        return await api.get(path);
    }
  },

  async getUnitsForSearchAndSelection(searchFilter, type, page, itemsPerPage) {
    switch (type) {
      default:
        let path = apiConfiguration.BASE_PATH_YARD + "gate/units-in-yard-without-handlings-out?page=" + (page-1) + "&size=" + itemsPerPage;
        if (searchFilter && searchFilter !== null && searchFilter !== "") {
          path += ("&filter=" + searchFilter);
        }
        return await api.get(path);
    }
  },

  async removeByHandlingId(handlingId) {
    await api.post(apiConfiguration.BASE_PATH_YARD + "gate/remove-by-handling-id/" + handlingId);
  },

  async addGoodsToTruckVisit(truckVisitId, goods) {
    await api.post(apiConfiguration.BASE_PATH_YARD + "gate/add-goods-to-truck-visit/" + truckVisitId + "/load", goods);
  },

  async addUnitsToTruckVisit(truckVisitId, units) {
    await api.post(apiConfiguration.BASE_PATH_YARD + "gate/add-units-to-truck-visit/" + truckVisitId + "/load", units);
  },

  async addCargoesToTruckVisit(truckVisitId, cargoes) {
    await api.post(apiConfiguration.BASE_PATH_YARD + "gate/add-units-to-truck-visit/" + truckVisitId + "/discharge", cargoes);
  },

  async getAdvicedUnitByObjectCode(unitCode) {
    let allUnits = await api.get(apiConfiguration.BASE_PATH_YARD + "unitvisits/findAllByObjectCode?objectCodes=" + unitCode);
    if (allUnits && allUnits !== null) {
      allUnits = allUnits.filter(unit => unit.status === "A");
    }
    return allUnits;
  }
  
};
