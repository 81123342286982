export default [
    {
        path: '/report/handlingHeader',
        view: 'report/HandlingHeader',
        meta: { requiresAuth: true }
    },
    {
        path: '/report/createHandlingHeader',
        view: 'report/CreateHandlingHeader',
        meta: { requiresAuth: true }
    },

    {
        path: '/report/layoverHeader',
        view: 'report/LayoverHeader',
        meta: { requiresAuth: true }
    },
    {
        path: '/report/createLayoverHeader',
        view: 'report/CreateLayoverHeader',
        meta: { requiresAuth: true }
    },

    {
        path: '/report/stockHeader',
        view: 'report/StockHeader',
        meta: { requiresAuth: true }
    },
    {
        path: '/report/createStockHeader',
        view: 'report/CreateStockHeader',
        meta: { requiresAuth: true }
    },

    {
        path: '/report/trainHeader',
        view: 'report/TrainHeader',
        meta: { requiresAuth: true }
    },
    {
        path: '/report/createTrainHeader',
        view: 'report/CreateTrainHeader',
        meta: { requiresAuth: true }
    },

    {
        path: '/report/manageReports',
        view: 'report/ManageReports',
        meta: { requiresAuth: true }
    },


];