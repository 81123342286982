import api from '../api';
import apiConfiguration from '../apiConfiguration';
import _ from 'lodash';

export default {
    getRailwayData: (stationName) => {
        const fullPath = `${apiConfiguration.BASE_PATH}binari/findByNomeParco/${stationName}`;
        return api.get(fullPath);
    },
    getCraneData: (stationName) => {
        return api.get(`json/gruData_${stationName}.json`);
    },
    getRailwayDataRT: (stationName) => {
        const fullPath = `${apiConfiguration.BASE_PATH}convogli/findRTByNomeParco/${stationName}`;
        return api.get(fullPath);
    },
    getParchiBinari: () => {
        const fullPath = `${apiConfiguration.BASE_PATH}parcoBinari/list`;
        return api.get(fullPath);
    },
    getBinariByParco: (parcoId) => {
        const fullPath = `${apiConfiguration.BASE_PATH}binari/findByParco/${parcoId}`;
        return api.get(fullPath);
    },
    getViaggiByParcoAndRangeDate: (stationName, dateFrom, dateTo) => {
        const fullPath = `${this.BASE_PATH}viaggiTreno/findByParcoAndRangeDate/${stationName}`;
        return api.get(fullPath, { dateFrom: dateFrom, dateTo: dateTo });
    },
    getViaggiByDateRange: (dateFrom, dateTo) => {
        const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/findByDateRange`;
        return api.get(fullPath, {
            params: { dateFrom: dateFrom, dateTo: dateTo }
        });
    },
    findAllPartenzeProgrammate: () => {
        const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/findAllPartenzeProgrammate`;
        return api.get(fullPath);
    },
    getConvogliByDateRange: (dateFrom, dateTo) => {
        const fullPath = `${apiConfiguration.BASE_PATH}convogli/findByDateRange`;
        return api.get(fullPath, {
            params: { dateFrom: dateFrom, dateTo: dateTo }
        });
    },
    getNomeConvogliPianificabili: () => {
        const fullPath = `${apiConfiguration.BASE_PATH}convogli/pianificabili`;
        return api.get(fullPath, {});
    },
    getConvoglioByName: (name)=> {
        const fullPath = `${apiConfiguration.BASE_PATH}convogli/findByName`;
        return api.get(fullPath, {
            params: { name: name}
        });
    },
    getConvogliInTerminal: () => {
        const fullPath = `${apiConfiguration.BASE_PATH}convogli/findConvogliInTerminal`;
        return api.get(fullPath);
    },
    getStatoBinari: async(parcoId, dateFrom, dateTo) => {
        try {
            const binariReq = await this.getBinariByParco(parcoId);
            const listBinari = binariReq.data;

            const tsFrom = dateFrom.getTime();
            const tsTo = dateTo.getTime();

            const tracceReq = await this.getViaggiByParcoAndRangeDate(parcoId, tsFrom, tsTo);
            const listTracce = tracceReq.data;

            const railsData = { tsFrom: tsFrom, tsTo: tsTo, rails: [] };

            _.forEach(listBinari, (b) => {
                let rail = {
                    id: b.id,
                    name: b.descrizione,
                    trains: []
                };
                _.forEach(_.filter(listTracce, { binarioId: b.id }), (t) => {
                    rail.trains.push({
                        id: t.id,
                        name: `${t.codiceStato} ${t.descrizione}`,
                        tsArrival: t.eta,
                        tsDeparture: t.etd,
                        start: 0,
                        end: 0
                    });
                })
                railsData.rails.push(rail);
            });

            return railsData;
        } catch (e) {
            this.onError(e);
        }
    },

    getListViaggiConvoglio: (convoglioId, currentViaggioInId) => {
        const fullPath = `${apiConfiguration.BASE_PATH}convogli/getListViaggiConvoglio`;
        return api.get(fullPath, {
            params: { convoglioId: convoglioId, currentViaggioInId: currentViaggioInId }
        });
    },

    getViaggioTrenoById: (id) => {
        const fullPath = `${apiConfiguration.BASE_PATH}viaggiTreno/${id}`;
        return api.get(fullPath);
    },
    listTracce: () => {
        const fullPath = `${apiConfiguration.BASE_PATH}tracce/listTracce`;
        return api.get(fullPath);
    },

    async getTurniByDate(date) {
        const fullPath = `${apiConfiguration.BASE_PATH}turni/findByDate/${date}`;
        return await api.get(fullPath);
    },

    async getConvoyWeight(id){
        const fullPath = `${apiConfiguration.BASE_PATH}convogli/get-weight/${id}`;
        return await api.get(fullPath);
    },

}
