import Vue from 'vue'
import store from '../../store/';

const REG_ACTION = "register";
const UNREG_ACTION = "unregister";
const PUSH_ACTION = "push";

let vm;

const getSocket = () => {
    if(!vm){
        vm = new Vue();
        vm.$socket.onmessage = onMessageCallback; 
        vm.$socket.onerror = (data) => console.log(data);
    }    
    return vm.$socket;
};

const onMessageCallback = (response) => {
    const responseData = JSON.parse(response.data);
    switch(responseData.action){
        case REG_ACTION:
            if(responseData.result === 'OK' && regActionsMap[responseData.group]){
                regActionsMap[responseData.group].active = true;
            }
            console.log(`registration to group ${responseData.group} with outcome ${responseData.result}`);
        break;
        case UNREG_ACTION:
            console.log(`de-registration to group ${responseData.group} with outcome ${responseData.result}`);
        break;
        case PUSH_ACTION:{
            if(regActionsMap[responseData.group] && regActionsMap[responseData.group].active && regActionsMap[responseData.group].callback instanceof Function){
                store.commit('app/loading', true);
                const data = JSON.parse(responseData.data);
                regActionsMap[responseData.group].callback(data);
                store.commit('app/loading', false);
            }            
        }
    }
};

const regActionsMap = {};

export const  wsHubRegister = (groupName, parameters, regCallback) => {
    const promise = new Promise((resolve, reject) => {
        try{
            const socket = getSocket();
            setTimeout(() => {
                if(socket.readyState !== 1){
                    console.log("SOCKET ", socket );
                    console.log("SOCKET.readyState ", socket.readyState,  socket.readyState !== 1 );
                    throw 'socket not in open state. state: ' +socket.readyState;
                }
                regActionsMap[groupName] = { callback: regCallback, active: false };
                socket.sendObj({
                    group: groupName,
                    action: 'register',
                    parameters: parameters,
                });
                
                setTimeout(() => {
                    if(!regActionsMap[groupName] || !regActionsMap[groupName].active){
                        console.log("Reject ", groupName, regActionsMap[groupName]);
                        reject();
                    } else {
                        resolve('registered');
                    }
                }, 300);
            }, 1000);
        } catch(e) {
            console.log("Error ", groupName, e);
            reject(e);
        }
    });
    return promise;
};

export const wsHubOnDataChangedEvent = (groupName) => {
    const promise = new Promise((resolve, reject) => {
        try{
            const socket = getSocket();
            if(socket.readyState !== 1){
                throw 'socket not in open state';
            }
            socket.sendObj({
                group: groupName,
                action: 'datachanged'
            });
            resolve('datachangedsent');
        } catch(e) {
            reject(e);
        }        
    });
    return promise;
};

export const wsHubUnregister = (groupName) => {
    const promise = new Promise((resolve, reject) => {
        try{
            const socket = getSocket();
            if(socket.readyState !== 1){
                throw 'socket not in open state';
            }
            socket.sendObj({
                group: groupName,
                action: 'unregister'
            });
            delete regActionsMap[groupName];
            resolve('unregistered');
        } catch(e) {
            reject(e);
        }
        
    });
    return promise;
};

export const wsHubIsGroupRegistered = groupName => regActionsMap[groupName] && regActionsMap[groupName].active;


