
export default {
    data() {
        return {
          customer: process.env.VUE_APP_CUSTOMER || '',          
        };
    },
    methods: {
        tLabel(labelIdx){
            return this.$te(`${this.customer}.${labelIdx}`) 
                ? this.$t(`${this.customer}.${labelIdx}`) 
                : this.$t(labelIdx); 
        },
        tLabelRequired(labelIdx){
            return this.tLabel(labelIdx)+" *";
        },
    },
    computed: {
        appName(){
            return this.tLabel('appName');
        },
        label_binario(){
            return this.tLabel('binario');
        },
        btn_title_exportCSV(){
            return this.tLabel('exportCSV');
        }
    }
}